import http from '../../http';

export const getQuestionList = (params) => {
  return http.get('/inquiry', { params: params });
};

export const getQuestionDetail = (inquiryId, password) => {
  return http.post(`/inquiry/${inquiryId}`, {
    password: password
  });
};

export const postCreatedQuestion = (params) => {
  return http.post('/inquiry', params);
};

export const putEditedQuestion = (params) => {
  return http.post(`/inquiry/${params.id}/put`, {
    inquiryType: params.inquiryType,
    name: params.name,
    phone: params.phone,
    email: params.email,
    password: params.password,
    title: params.title,
    content: params.content,
  });
};
