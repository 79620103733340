import { ReactComponent as Eyes } from '../../assets/icons/web/eye.svg';
import { ReactComponent as EyesOff } from '../../assets/icons/web/eyeoff.svg';

const PasswordInput = ({ value, id, onChange, placeholder, setFocus, className, hidden, onClick, disabled }) => {
  return (
    <div className={'flex-1 w-full max-w-[950px] relative'}>
      <input
        type={hidden ? 'password' : 'text'}
        disabled={disabled}
        className={`w-full border-darkDB border-[1px] rounded-[10px] outline-none focus:border-main py-[13px] px-[20px] text-dark49 text-bodyXSmall leading-[24px] h-[50px] sm:h-[48px] xs:h-[44px] fold:h-[44px] placeholder:text-darkDB ${
          className || ''
        }`}
        id={id}
        autoComplete="off"
        // value={value || ''}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {!disabled && (
        <div className={'absolute top-1/2 -translate-y-[43%] right-[20px] cursor-pointer'} onClick={onClick}>
          {hidden ? <Eyes fill="#747e90" /> : <EyesOff fill="#747e90" />}
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
