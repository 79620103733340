import React, { memo, useCallback, useEffect, useRef } from 'react';
import { styled } from 'styled-components';

const PassWordModal = ({ status, setShowModal, state, setState, onClick }) => {
  const modalInputRef = useRef(null);
  const handleOnClickModal = useCallback(
    (bool) => {
      if (bool) return onClick();
      setShowModal(false);
    },
    [state, status]
  );
  useEffect(() => {
    if (!status && modalInputRef.current) modalInputRef.current.focus();
  }, [status, onClick]);
  const handleOnKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') return onClick();
      return true
    },
    [state]
  );
  return (
    <ModalWrapper>
      <ModalBox className={'w-[580px] mx-[20px] xs:w-full fold:w-full '}>
        <div
          className={`title text-bodyNormal font-[500] leading-[30px] ${
            status ? 'text-dark1A' : 'text-[rgba(255,59,59,1)]'
          }`}>
          {status ? '비밀번호를 입력해 주세요.' : '비밀번호를 다시 입력해 주세요.'}
        </div>
        <form>
          <input type="text" name="email" autoComplete="username email" className={'hidden'} />
          <input
            id="passwordDialogInput"
            type="password"
            name={'password'}
            className={`pwInput outline-none border-[1px] border-solid border-darkDB  ${
              status ? 'focus:border-main' : 'focus:border-[rgba(255,59,59,1)] border-[rgba(255,59,59,1)]'
            }`}
            ref={modalInputRef}
            placeholder={'비밀번호를 입력해 주세요.'}
            autoComplete={'new-password'}
            onChange={({ target: { value } }) => {
              setState((state) => ({
                ...state,
                password: value.trim(),
              }));
            }}
            onKeyDown={handleOnKeyDown}
          />
        </form>
        <div className={'btnBox'}>
          <button className="cancel" onClick={() => handleOnClickModal(false)}>
            취소
          </button>
          <button className="success" onClick={() => handleOnClickModal(true)}>
            확인
          </button>
        </div>
      </ModalBox>
    </ModalWrapper>
  );
};

export default memo(PassWordModal);

const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 21;
  background-color: rgba(58, 58, 60, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
`;

const ModalBox = styled.div`
  position: relative;
  background-color: #fff;
  padding: 40px;
  min-width: 250px;

  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  border-radius: 20px;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
  @media all and (max-width: 600px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  & .title {
    padding-bottom: 30px;
    text-align: start;
    @media all and (max-width: 600px) {
      font-size: 18px;
      padding-bottom: 15px;
    }
  }
  & form {
    & .pwInput {
      width: 100%;
      color: rgba(73, 73, 73, 1);
      border-radius: 11px;
      padding: 15px 20px;
      margin-bottom: 40px;
      @media all and (max-width: 600px) {
        margin-bottom: 30px;
      }
    }
  }
  & .btnBox {
    display: flex;
    flex-direction: row;
    justify-content: end;
    & button {
      height: 47px;
      padding: 10px 30px;
      border-radius: 10px;
      cursor: pointer;
      &.cancel {
        margin-right: 10px;
        color: #fff;
        border: 1px solid var(--color-admin-border);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        background: rgba(73, 73, 73, 1);
      }
      &.success {
        background: rgba(0, 129, 255, 1);
        color: #fff;
      }
    }
  }
  @media all and (max-width: 510px) {
  }
`;
